/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


/**
 * google maps 
 * 
 */
var locationsMap;
var map;
function initMap() {
  var myLatLng1 = {lat: 55.9606179, lng: -3.2230097};
  var myLatLng2 = {lat: 55.9606179, lng: -3.2230097};


  function pinSymbol(color) {
      return {
          path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
          fillColor: '#1486BD',
          fillOpacity: 1,
          strokeColor: '#106A96',
          strokeWeight: 1,
          scale: 1,
     };
  }

  /* BANNER MAP */
  if (document.getElementById('banner-map') !== null) {
    var mapDiv;
    
    
      mapDiv = 'banner-map';
    
    map = new google.maps.Map(document.getElementById(mapDiv), {
      center: myLatLng1,
      zoom: 16,
      scrollwheel: false
    });

    var marker = new google.maps.Marker({
      position: myLatLng2,
      map: map,
      title: 'The Championships',
      icon: pinSymbol()
    });

    var contentString = '<p><strong>The Championships</strong></p>'+
	'<p>13th March 2016</p>'+
	'<p>at Broughton High School</p>'; 

    var infowindow = new google.maps.InfoWindow({
      content: contentString
    });
    
    infowindow.open(map, marker);
    map.panBy(0, -120);

  }



  
}
